import { environment } from 'src/environments/environment';

const PRODUCT_IMAGE_LIMIT = 50;

export const CONSTANT = {
  TOKEN_TYPE: 'Bearer',
  CMS_TITLE: 'OrderAxe',
  CDN: environment.cdn,
  CRYPT_KEY: '67b69634f9880a282c14a0f0cb7ba20cf5d677e9',
  SERVER_FAILURE_MESSAGE: 'Something went wrong! please try again.',
  NO_RECORD_FOUND: 'No records found',
  NO_TEAM_USER_FOUND: 'No records found',
  NO_BRAND_FOUND: 'No Brand(s) has been added yet.',
  NO_RETAILER_FOUND: 'No Retailer(s) are added yet.',
  ORG_BRAND: 'Brand',
  ORG_DISTRIBUTOR: 'Distributor',
  ORG_RETAILER: 'Retailer',
  ODR_SUBMIT: 'submitted',
  ODR_SAVE: 'saved',
  CHUNK_LIMIT: 250,
  INV_CHUNK_LIMIT: 500,
  ORDER_CHUNK_LIMIT: 250,
  UNSAVED_HEAD: 'Unsaved Changes',
  UNSAVED_TXT: `It looks like you have been editing something.<br> If you leave before saving, your changes will be discarded`,
  PROD_IMAGE_FACTOR: {
    max: 1500,
    min: 320,
  },
  PROD_IMAGE_FACTOR_NEW: {
    min: {
      height: 320,
      width: 240,
    },
    max: {
      height: 1500,
      width: 1125,
    },
  },

  MOBILE_BANNER_IMAGE_FACTOR: {
    max: 1500,
    min: 320,
  },
  MOBILE_BANNER_IMAGE_FACTOR_NEW: {
    min: {
      height: 420,
      width: 360,
    },
    max: {
      height: 1540,
      width: 1320,
    },
  },
  DESKTOP_BANNER_IMAGE_FACTOR: {
    max: 2560,
    min: 1280,
  },

  DESKTOP_BANNER_IMAGE_FACTOR_NEW: {
    min: {
      height: 720,
      width: 1280,
    },
    max: {
      height: 900,
      width: 1600,
    },
  },
  DESKTOP_HINT: `Image should be bigger than 1280 X 720 (1600 X 900 or bigger recommended) only jpeg,jpg and png format are allowed.`,
  MOBILE_HINT: `Image should be bigger than 420 X 360 (1540 X 1320 or bigger recommended) only jpeg,jpg and png format are allowed.`,
  PRODUCT_HINT: `Image should be bigger than 240 X 320 (1125 X 1500 or bigger recommended) only jpeg,jpg and png format are allowed.`,
  CATEGORY_HINT: `Image should be bigger than 600 X 600 (1500 X 1500 or bigger recommended) only jpeg,jpg and png format are allowed`,
  DESKTOP_LIMIT: 5,
  MOBILE_LIMIT: 5,
  PRODUCT_IMAGE_LIMIT,
  MOBILE_LIMIT_TXT: 'Your mobile banners limit reached, remove existing to add new.',
  DESKTOP_LIMIT_TXT: 'Your desktop banners limit reached, remove existing to add new.',
  PRODUCT_LIMIT_TXT: 'Your product images limit reached, remove existing to add new.',
  PRODUCT_LIMIT_MAX: `You can upload a maximum of ${PRODUCT_IMAGE_LIMIT} images`,
  MAX_DESK_IMAGE_FACTOR: 2560,
  MIN_DESK_IMAGE_FACTOR: 1280,
  PAGE_SIZE: 25,
  EXTRA_INFO: 'only for additional information',
  NO_SHEET_XLS: 'Invalid Excel !! No sheet present.',
  EXTRA_SHEET_XLS: 'Invalid Excel!! More than 1 sheets present!',
  EMPTY_XLS: 'Empty file!',
  NO_DATA_XLS: 'No data in xls file',
  XLS_RECORD_LIMIT: 50000,
  INVENTORY_XLS_RECORD_LIMIT: 50000,
  INVALID_HEADER_XLS: 'Xls file contains invalid headers.',
  TAGS_LIMIT: 10,
  EXCEL_PREFIX: 'OX',
  BARCODE_MIN: 2,
  BARCODE_MAX: 20,
  RETAILER_XLX_STATIC_HEADER: [
    'id system generated',
    'retailer name',
    'owner name',
    'retailer reference id',
    'cc country code',
    'phone number',
    'cc country code',
    'other phone number',
    'email',
    'pan',
    'gstin',
    'address line 1',
    'address line 2',
    'city',
    'state',
    'country',
    'zip code',
    'transporter name',
    'transporter gstin',
    'transporter address line 1',
    'transporter address line 2',
    'transporter city',
    'transporter state',
    'transporter country',
    'transporter zip',
    // 'full name',
    // 'cc country code',
    // 'phone number',
    // 'address line 1',
    // 'address line 2',
    // 'city',
    // 'state',
    // 'country',
    // 'zip code'
  ],
  RETAILER_XLX_HEADER_COLUMN: [
    'A2',
    'B2',
    'C2',
    'D2',
    'E2',
    'F2',
    'G2',
    'H2',
    'I2',
    'J2',
    'K2',
    'L2',
    'M2',
    'N2',
    'O2',
    'P2',
    'Q2',
    'R2',
    'S2',
    'T2',
    'U2',
    'V2',
    'W2',
    'X2',
  ],
  RETAILER_XLX_STATIC_HEADER_TEST: [
    { name: 'id system generated', order: 0 },
    { name: 'retailer name', order: 1 },
    { name: 'owner name', order: 2 },
    { name: 'retailer reference id', order: 3 },
    { name: 'cc country code', order: 4 }, // cc phone number
    { name: 'phone number', order: 5 }, // phone number
    { name: 'cc country code', order: 6 }, // cc other phone number
    { name: 'other phone number', order: 7 }, // other phone number
    { name: 'email', order: 8 },
    { name: 'pan', order: 9 },
    { name: 'gstin', order: 10 },
    { name: 'address line 1', order: 11 },
    { name: 'address line 2', order: 12 },
    { name: 'city', order: 13 },
    { name: 'state', order: 14 },
    { name: 'country', order: 15 },
    { name: 'zip code', order: 16 },
    { name: 'transporter name', order: 17 },
    { name: 'transporter gstin', order: 18 },
    { name: 'transporter address line 1', order: 19 },
    { name: 'transporter address line 2', order: 20 },
    { name: 'transporter city', order: 21 },
    { name: 'transporter state', order: 22 },
    { name: 'transporter country', order: 23 },
    { name: 'transporter zip', order: 24 },
  ],
  PRODUCT_XLX_HEADER_COLUMN: [
    'A2',
    'B2',
    'C2',
    'D2',
    'E2',
    'F2',
    'G2',
    'H2',
    'I2',
    'J2',
    'K2',
    'L2',
    'M2',
    'N2',
    'O2',
    'P2',
    'Q2',
    'R2',
    'S2',
    'T2',
    'U2',
    'V2',
    'W2',
    'X2',
    'Y2',
    'Z2',
    'AA2',
    'AB2'
  ],
  SIZES: [
    'XS',
    'S',
    'M',
    'L',
    'XL',
    'XXL',
    '2XL',
    'XXXL',
    '3XL'
  ],
  ALL_PERMISSIONS_TYPE: ['view', 'manage'],
  ALL_PERMISSIONS_ARRAY: [
    {
      key: 'dashboard',
      name: 'Dashboard',
      values: ['view', ''],
      auto_enabled: {}
    },
    {
      key: 'products',
      name: 'Products',
      values: ['view', 'manage'],
      auto_enabled: {
        view: {
          inventory: ['view'],
        },
        manage: {
          inventory: ['create', 'view', 'edit', 'delete', 'manage'],
        }
      },
      auto_disabled: {
        view: {
          challans: ['create', 'edit', 'delete', 'manage'],
          catelogues: ['create', 'view', 'edit', 'delete', 'manage'],
          inventory: ['create', 'view', 'edit', 'delete', 'manage'],
          self_orders: ['create', 'view', 'edit', 'delete', 'manage'],
          all_orders: ['create', 'view', 'edit', 'delete', 'manage'],
        },
        manage: {
          inventory: ['create', 'edit', 'delete', 'manage'],
        }
      }
    },
    {
      key: 'catelogues',
      name: 'Catalogue',
      values: ['view', 'manage'],
      auto_enabled: {
        view: {
          products: ['view']
        },
        manage: {}
      }
    },
    {
      key: 'brands',
      name: 'Brands',
      values: ['view', 'manage'],
      auto_enabled: {
        view: {},
        manage: {}
      }
    },
    {
      key: 'distributors',
      name: 'Distributors',
      values: ['view', 'manage'],
      auto_enabled: {
        view: {},
        manage: {}
      }
    },
    {
      key: 'retailers',
      name: 'Retailers',
      values: ['view', 'manage'],
      auto_enabled: {
        view: {},
        manage: {}
      }
    },
    // {
    //   key: 'orders',
    //   name: 'Orders',
    //   values: ['view', 'manage', 'create'],
    //   auto_enabled: {
    //     view: {
    //       products: ['view'],
    //     },
    //     manage: {
    //       products: ['view'],
    //     },
    //     create: {
    //       products: ['view'],
    //       retailers: ['view'],
    //       distributors: ['view']
    //     }
    //   }
    // },
    {
      key: 'challans',
      name: 'Challans',
      values: ['view', 'manage'],
      auto_enabled: {
        view: {},
        manage: {
          distributors: ['view'],
          retailers: ['view'],
          dispatch: ['create', 'view', 'edit', 'delete', 'manage'],
        }
      },
      auto_disabled: {
        view: {
          dispatch: ['create', 'view', 'edit', 'delete', 'manage'],
          invoices: ['create', 'edit', 'delete', 'manage'],
        },
        manage: {
          dispatch: ['create', 'view', 'edit', 'delete', 'manage'],
        }
      }
    },
    {
      key: 'dispatch',
      name: 'Dispatch',
      values: ['', 'manage'],
      auto_enabled: {
        view: {},
        manage: {
          distributors: ['view'],
          retailers: ['view'],
          challans: ['create', 'view', 'edit', 'delete', 'manage'],
        }
      },
      auto_disabled: {
        view: {},
        manage: {
        }
      }
    },
    {
      key: 'invoices',
      name: 'Invoices',
      values: ['view', 'manage'],
      auto_enabled: {
        view: {
        },
        manage: {
          challans: ['view'],
          distributors: ['view'],
          retailers: ['view'],
        }
      }
    },
    {
      key: 'team',
      name: 'Team',
      values: ['view', ''],
      auto_enabled: {
        view: {},
        manage: {},
      }
    },
    {
      key: 'reports',
      name: 'Reports',
      values: ['view', ''],
      auto_enabled: {
        view: {},
        manage: {},
      }
    },
    {
      key: 'inventory',
      name: 'Inventory',
      values: ['view', 'manage'],
      auto_enabled: {
        view: {
          products: ['view']
        },
        manage: {
          products: ['create', 'view', 'edit', 'delete', 'manage'],
        }
      },
      auto_disabled: {
        view: {
          products: ['create', 'view', 'edit', 'delete', 'manage'],
        },
        manage: {
          products: ['create', 'edit', 'delete', 'manage'],
        }
      }
    },
    {
      key: 'settings',
      name: 'Settings',
      values: ['view', 'manage'],
      auto_enabled: {
        view: {},
        manage: {},
      }
    },
    {
      key: 'trips',
      name: 'Schedule Board',
      values: ['view', 'manage'],
      disabled: true,
      auto_enabled: {
        view: {},
        manage: {},
      }
    },
    {
      key: 'support',
      name: 'Support',
      values: ['view', 'manage'],
      auto_enabled: {
        view: {},
        manage: {},
      }
    },
  ],
  ALL_PERMISSIONS_ARRAY_DISTRIBUTOR: [
    {
      key: 'dashboard',
      name: 'Dashboard',
      values: ['view', ''],
      auto_enabled: {}
    },
    {
      key: 'products',
      name: 'Collections',
      values: ['view', ''],
      auto_enabled: {
        view: {},
        manage: {}
      },
      auto_disabled: {
        view: {},
        manage: {}
      }
    },
    {
      key: 'catelogues',
      name: 'Catalogue',
      values: ['view', ''],
      auto_enabled: {
        view: {
          products: ['view']
        },
        manage: {}
      }
    },
    {
      key: 'brands',
      name: 'Brands',
      values: ['view', ''],
      auto_enabled: {
        view: {},
        manage: {}
      }
    },
    {
      key: 'retailers',
      name: 'Retailers',
      values: ['view', 'manage'],
      auto_enabled: {
        view: {},
        manage: {}
      }
    },
    // {
    //   key: 'orders',
    //   name: 'Orders',
    //   values: ['view', 'manage', 'create'],
    //   auto_enabled: {
    //     view: {
    //       products: ['view'],
    //     },
    //     manage: {
    //       products: ['view'],
    //     },
    //     create: {
    //       products: ['view'],
    //       retailers: ['view'],
    //       distributors: ['view']
    //     }
    //   }
    // },
    {
      key: 'team',
      name: 'Team',
      values: ['view', ''],
      auto_enabled: {
        view: {},
        manage: {},
      }
    },
    {
      key: 'reports',
      name: 'Reports',
      values: ['view', ''],
      auto_enabled: {
        view: {},
        manage: {},
      }
    },
    {
      key: 'settings',
      name: 'Settings',
      values: ['view', 'manage'],
      auto_enabled: {
        view: {},
        manage: {},
      }
    },
  ],
  ORDERS_PERMISSION: [
    {
      key: 'self_orders',
      name: 'Self Orders',
      values: ['', 'manage'],
      auto_enabled: {
        view: {},
        manage: {
          products: ['view'],
          retailers: ['view']
        },
      }
    },
    {
      key: 'all_orders',
      name: 'All Orders',
      values: ['view', 'manage'],
      auto_enabled: {
        view: {},
        manage: {
          self_orders: ['create', 'view', 'edit', 'delete', 'manage'],
          products: ['view'],
          retailers: ['view']
        },
      }
    },
  ],
  PERMISSION: {
    dashboard: ['view', 'manage'],
    products: ['create', 'view', 'edit', 'manage'],
    catelogues: ['create', 'view', 'edit', 'manage'],
    retailers: ['create', 'view', 'edit', 'manage'],
    distributors: ['create', 'view', 'edit', 'manage'],
    brands: ['view', 'manage'],
    self_orders: ['create', 'view', 'edit', 'delete', 'manage'],
    all_orders: ['view', 'edit', 'delete', 'manage'],
    reports: ['view', 'manage'],
    team: ['create', 'view', 'edit', 'manage'],
    taxes: ['create', 'view', 'edit', 'delete', 'manage'],
    settings: ['create', 'view', 'edit', 'delete', 'manage'],
    challans: ['create', 'view', 'edit', '', 'manage'],
    dispatch: ['create', 'view', 'edit', '', 'manage'],
    invoices: ['create', 'view', 'edit', 'delete', 'manage'],
    inventory: ['view', 'edit', 'manage'],
    trips: ['view', 'manage'],
    support: ['view', 'manage'],
  },
  PERMISSION_KEY: {
    dashboard: ['dashboard'],
    products: ['products'],
    retailers: ['retailers'],
    catelogues: ['catelogues'],
    distributors: ['distributors'],
    brands: ['brands'],
    orders: ['self_orders', 'all_orders'],
    reports: ['reports'],
    team: ['team'],
    taxes: ['taxes'],
    settings: ['settings'],
    challans: ['challans'],
    dispatch: ['dispatch'],
    invoices: ['invoices'],
    inventory: ['inventory'],
    trips: ['trips'],
    tickets: ['tickets'],
    add: 'create',
    edit: 'edit',
    view: 'view',
    delete: 'delete',
    manage: 'manage',
  },
  RANDOM_STRING: (Math.random() + 1).toString(36).substring(2),
  CATALOGUE_XLX_STATIC_HEADER: [
    'barcode'
  ],
  orderVia: 'catelogue',
  SELECT_MEETING_LIMIT: 100,
  MEETING_LIMIT_TXT: 'Your meetings limit reached, remove existing to add new.',
  reportExportLimit: 40000,
  CHALLAN_REPORT_EXPORT_LIMIT: 40000,
  CHALLAN_REPORT_EXPORT_LIMIT_REGULAR: 5000,
  ORDER_QTY_LIMIT: 5,
  ORDER_QTY_MASK_LIMIT: '99999'
};
