import { HeaderService } from './modules/layout/header/header.service';
import { ROUTES_CONFIG } from 'src/app/shared/routes';
import { UsersService } from './shared/services/api/users.service';
import { Component, OnInit, OnChanges, HostListener, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, RouterOutlet, NavigationStart, NavigationCancel } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';
import { APPCONFIG } from './config';

// Service
import { AuthService } from './shared/services/auth.service';
import { StorageService } from './shared/services/storage.service';
import { UtilService } from './shared/services/util.service';

// constant
import { CONSTANT } from './shared/constant';
// jQuery
import * as jQuery from 'jquery';

import { Subscription } from 'rxjs';
import { LocationService } from './shared/services/api/location.service';
import { HttpUrlEncodingCodec } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';

export let browserRefresh = false;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent implements OnInit, OnChanges, OnDestroy {
  test = false;
  appRouteAnimation: any;
  lang = 'EN';
  message;
  public AppConfig: any;
  public isAuthPage: boolean;
  public audioLoaderClass: string;
  checkPageOrNot = true;
  subscription: Subscription;
  previousUrl: any;
  currentUrl: any;

  codec = new HttpUrlEncodingCodec();

  @HostListener('window:resize')
  onResize() {
    this.setWindowHeight();
    if (window.innerWidth >= 1280) {
      return;
    }
    document.querySelector('body').classList.remove('sibebarIsOpen');
  }

  constructor(
    private authService: AuthService,
    private storageService: StorageService,
    private router: Router,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    public utilService: UtilService,
    private locService: LocationService,
    private userService: UsersService,
    private headerService: HeaderService,
    private dialog: MatDialog
  ) {

    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
      }
    });
    // const authtoken = this.storageService.get(environment.auth_token_key);
    // const userObject = this.storageService.get('userobject');
    // if (authtoken && authtoken.trim().length > 0 && userObject !== undefined && userObject.user.name === undefined) {
    //   this.clearLogout();
    // }

    this.utilService.displayEditOrgFrmChange.subscribe(value => {
      this.test = value;
    });

    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {

        this.previousUrl = this.currentUrl;
        this.currentUrl = e.url;
        this.utilService.setPreviousUrl(this.previousUrl);

      }
      /**
       * show loader when route update
       */
      if (e instanceof NavigationStart) {
        document.querySelector('body').classList.remove('sidebarIsOpen');
        document.querySelector('body').classList.remove('productSidebarOpen');
        this.dialog.closeAll();
        this.ngOnChanges({ className: 'show-loader' });
      } else if (
        e instanceof NavigationEnd ||
        e instanceof NavigationCancel
      ) {
        document.documentElement.style.setProperty('--removableHeight', `0`);
        this.ngOnChanges({ className: 'hide-loader' });
      }
    });
  }


  /**
   * detect update
   */
  ngOnChanges(loaderObj: any) {
    this.audioLoaderClass = loaderObj.className;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    /**
     * set page title and meta detail
     */
    this.checkAuthToken();
    const cmsTitle = this.titleService.getTitle();
    this.router
      .events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child.firstChild) {
            child = child.firstChild;
          }
          if (child.snapshot.data.title) {
            return child.snapshot.data.title;
          }
          return cmsTitle;
        })
      ).subscribe((pageTitle: string) => {
        this.titleService.setTitle(`${pageTitle} - ${CONSTANT.CMS_TITLE} CMS`);
      });

    // ------------

    this.setWindowHeight();
    this.AppConfig = APPCONFIG;
    this.getAllCountries();
    this.getCurrentCountry();
  }

  private setWindowHeight(): void {
    document.documentElement.style.setProperty('--windowInnerHeight', `${window.innerHeight}px`)
  }

  getAllCountries() {
    this.subscription = this.locService.getCountryList().subscribe(res => {
      if (res !== null && res.status.toLowerCase() === 'success') {
        this.locService.allCountries$.next(res.countries);
      }
    });
  }
  getCurrentCountry() {
    this.utilService.getCurrentCountry$().subscribe((res: any) => {
      if (res !== null && res.status.toLowerCase() === 'success') {
        this.utilService.currentCountry$.next(res.country);
      }
    });
  }

  /**
   *
   * @param outlet router outlet
   */
  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }

  checkAuthToken() {

    const url = window.location.pathname.substring(window.location.pathname.indexOf("/") + 1).split('/');

    if (!this.authService.isLoggedIn()) {
      //  || url[0]?.toLowerCase() === 'catalogues'
      return;
    }

    let selectedOrg;
    const userApiParams = {
      cc: 'IN'
    };
    const userApiResponse = this.userService.getUserDetail(ROUTES_CONFIG.GET_USER_DETAIL, userApiParams);
    userApiResponse.subscribe(res => {
      if (res.status.toLowerCase() === 'success') {
        if (res.user.name === undefined || res.user.name === '') {
          this.clearLogout();
        } else {
          this.storageService.set('userObject', res);
          if (!!res.noti_unread) {
            this.storageService.set('noticeUnread', res.noti_unread);
            this.userService.setHeaderNotify(res.noti_unread);
          }
          this.authService.updateIsSignupProcessComplete.next(true);
          const activatedOrganisations = res.organisations.filter(org => org.is_team_member_active && org.active);
          if (res.organisations.length === 0 || activatedOrganisations.length === 0) {
            this.authService.isUserDetailSuccess.next(true);
            return;
          }
          selectedOrg = activatedOrganisations.find(org => org.selected);
          if (selectedOrg !== undefined) {
            this.storageService.updateSelectedOrgDetail(selectedOrg, 'app');
          } else {
            this.storageService.updateSelectedOrgDetail(activatedOrganisations[0], 'app');
          }
          this.authService.isUserDetailSuccess.next(true);
        }
      } else {
        this.clearLogout();
      }
    });
  }

  clearLogout() {
    this.headerService.logout().subscribe((res: any) => { });
    this.authService.updateIsSignupProcessComplete.next(false);
    this.authService.updateIsLoginProcessComplete.next(false);
    this.storageService.clearSessionKeys();
    this.router.navigate(['/login']);
  }


}
